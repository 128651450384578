<template>
  <div>
    <div class="list-g">
      <el-input type="text" class="inputbox-g" v-model="value" size="mini"></el-input>
      <el-button type="primary" @click="search" size="mini">搜索</el-button>
    </div>
    <el-dialog :visible.sync="show" width="60%" top="8vh" @close="resData = []">
      <h3 class="title-g">搜索结果</h3>
      <div v-if="resData.length === 0">无结果</div>
      <div v-else v-for="(item, key) in resData" :key="key" style="margin: 20px">
        <span style="margin-right: 40px">{{ item[name] }}</span>
        <el-button type="primary" @click="select(item)" size="mini">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//父组件 bizlist,storelist,userlist,coupons, orders,
import {
  getOrderBySn,
} from "@/api/api";
export default {
  props: ["searchType", "searchKey", "name"], //type和key指定搜索表名和字段，name：搜索结果的显示字段
  data() {
    return {
      value: "",
      show: false,
      //返回所有符合条件的结果，数组
      resData: []
    };
  },
  methods: {
    getOrderListFn(data) {
      const that = this;
      getOrderBySn(data).then(res => {
        if (res.result) {
          that.resData = res.data;
          that.show = true;
        }
      });
    },

    search() {
      if (this.value === "") {
        this.$alert("不能为空", {
          type: "warning",
          callback: () => {}
        });
      } else {
        let data = {
          page: 1,
          ordersn: ""
        };
        data[this.searchKey] = this.value;
        this.getOrderListFn(data);
      }
    },
    select(item) {
      this.$emit("get", item);
      this.show = false;
    }
  }
};
</script>

<style>
</style>