<template>
  <div>
    <h3 class="title-g">
      订单
      <div class="searchbox-g">
        <div>订单号搜索</div>
        <search :searchType="'orders'" :searchKey="'ordersn'" :name="'orderSn'" @get="getDetail" />
      </div>
    </h3>
    <el-tabs v-model="activeName" @tab-click="handleTabChange">
      <el-tab-pane label="景区订单" name="1"></el-tab-pane>
      <el-tab-pane label="预定订单" name="2"></el-tab-pane>
      <el-tab-pane label="退款订单" name="3"></el-tab-pane>
      <el-tab-pane label="待结算订单" name="4"></el-tab-pane>
    </el-tabs>
    <orderlisttable :orders="orders" />
    <el-pagination
      :page-size="30"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>

    <!-- 搜素结果弹出框 -->
    <el-dialog :visible.sync="dialogVisible" width="60%" top="8vh">
      <div style="margin: 30px">
        <el-descriptions title="订单详情" :column="3" border style="margin:20px 0">
          <el-descriptions-item
            v-for="(value, key) in tableList.orderHead"
            :key="key"
            :label="value"
          >{{ orderShow[key] }}</el-descriptions-item>
        </el-descriptions>

        <el-table :data="prodShow" style="width: 100%">
          <el-table-column
            :prop="key"
            :label="value"
            v-for="(value, key) in tableList.prodHead"
            :key="key"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import orderlisttable from "../components/OrderListTable";
import search from "../components/Search";
import { adminGetOrderList } from "@/api/api";
export default {
  components: {
    orderlisttable,
    search
  },
  data() {
    return {
      activeName: "1",
      page: 1,
      state: 1,
      storeType: 0,
      orders: [],
      total: 0,
      orderShow: {},
      prodShow:[],
      dialogVisible: false,
      balanceDate: null,
      tableList: {
        orderShortHead: {
          orderSn: "订单号",
          amount: "金额",
          nameStore: "商家名称",
          orderFromName: "APP",
          nickName: "用户昵称 ",
          genTime: "时间"
        },
        orderHead: {
          orderSn: "订单号",
          state: "状态",
          settleDate: "消费/退款时间",
          idStore: "商家编号",
          nameStore: "商家名称",
          idUser: "用户编号",
          nickName: "用户昵称 ",
          orderFromName: "APP",
          amount: "金额",
          cashAmount: "现金",
          par: "优惠券金额",
          incomeStore: "商家收入",
          incomeAxiche: "平台佣金",
          cashChannelName: "支付渠道",
          balanceDate: "渠道结算时间",
          couponCode: "券码",
          genTime: "下单时间"
        },
        prodHead: {
          orderSn: "订单编号",
          id: "商品ID",
          prodName: "商品名称",
          prodPrice: "商品价格",
          quantity: "数量"
        }
      }
    };
  },
  mounted() {
    this.getOrderListFn();
  },
  methods: {
    getOrderListFn() {
      const that = this;
      adminGetOrderList({
        page: that.page,
        storeType: that.storeType,
        state: that.state,
        idUser: "",
        idStore: "",
        balanceDate: that.balanceDate
      }).then(res => {
        if (res.result) {
          let oldOrders = that.orders;
          that.orders = oldOrders.concat(res.data.orders);
          that.total = res.data.total;
        }
      });
    },
    getDetail(item) {
      this.orderShow = item;
      this.prodShow = item.products;
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrderListFn();
    },
    handleTabChange() {
      switch (this.activeName) {
        case "1":
          this.page = 1;
          this.orders = [];
          this.storeType = 1;
          this.state = 1;
          this.balanceDate = null;
          break;

        case "2":
          this.page = 1;
          this.orders = [];
          this.storeType = null;
          this.state = 0;
          this.balanceDate = null;
          break;
        case "3":
          this.page = 1;
          this.orders = [];
          this.storeType = null;
          this.state = -1;
          this.balanceDate = null;
          break;
        case "4":
          this.page = 1;
          this.orders = [];
          this.storeType = null;
          this.state = 1;
          this.balanceDate = 1;
          break;
      }
      this.getOrderListFn();
    }
  }
};
</script>

<style scoped>
.list-g h3 {
  padding-right: 100px;
}
.list-g div {
  min-width: 75px;
}
</style>